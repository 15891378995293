import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

const Layout = React.lazy(() => import('./components/Layout'));
const Inner1 = React.lazy(() => import('./pages/Inner1'));
const Inner2 = React.lazy(() => import('./pages/Inner2'));
const Inner3 = React.lazy(() => import('./pages/Inner3'));
const Inner4 = React.lazy(() => import('./pages/Inner4'));
const Inner5 = React.lazy(() => import('./pages/Inner5'));
const Inner6 = React.lazy(() => import('./pages/Inner6'));
const Inner7 = React.lazy(() => import('./pages/Inner7'));
const Inner8 = React.lazy(() => import('./pages/Inner8'));
const Inner9 = React.lazy(() => import('./pages/Inner9'));
const Inner19 = React.lazy(() => import('./pages/Inner19'));
const Inner18 = React.lazy(() => import('./pages/Inner18'));
const Inner17 = React.lazy(() => import('./pages/Inner17'));
const Inner16 = React.lazy(() => import('./pages/Inner16'));
const Inner15 = React.lazy(() => import('./pages/Inner15'));
const Inner14 = React.lazy(() => import('./pages/Inner14'));
const Inner13 = React.lazy(() => import('./pages/Inner13'));
const Inner12 = React.lazy(() => import('./pages/Inner12'));
const Inner11 = React.lazy(() => import('./pages/Inner11'));
const Inner10 = React.lazy(() => import('./pages/Inner10'));
const JobDetails = React.lazy(() => import('./pages/JobDetails'));
const JobForm = React.lazy(() => import('./components/JobForm'));
const Home = React.lazy(() => import('./pages/Home'));
const Industry4 = React.lazy(() => import('./pages/Industry4'));
const CaseStudies = React.lazy(() => import('./pages/CaseStudies'));
const Sectors = React.lazy(() => import('./pages/Sectors'));
const Services = React.lazy(() => import('./pages/Services'));
const About = React.lazy(() => import('./pages/About'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Career = React.lazy(() => import('./pages/Career'));
const CareerInner = React.lazy(() => import('./pages/CareerInner'));
const Refund = React.lazy(() => import('./pages/Refund'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const Cookie = React.lazy(() => import('./pages/Cookie'));
const PaymentGateway = React.lazy(() => import('./pages/PaymentGateway'));
const TnC = React.lazy(() => import('./pages/TnC'));
const NoPage = React.lazy(() => import('./pages/NoPage'));
const ShippingPolicy = React.lazy(() => import('./pages/ShippingPolicy'));

function App() {
  return (
    <>
      <HelmetProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Home />} />
          <Route path="industry4" element={<Industry4 />} />
          <Route path="case-studies" element={<CaseStudies />} />
          <Route path="sectors/:sector" element={<Sectors />} />
          <Route path="sectors" element={<Sectors />} />
          <Route path="services/:service" element={<Services />} />
          <Route path="services" element={<Services />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="career" element={<Career />} />
          <Route path="career-inner" element={<CareerInner />} />
          <Route path="inner/1" element={<Inner1 />} />
          <Route path="inner/2" element={<Inner2 />} />
          <Route path="inner/3" element={<Inner3 />} />
          <Route path="inner/4" element={<Inner4 />} />
          <Route path="inner/5" element={<Inner5 />} />
          <Route path="inner/6" element={<Inner6 />} />
          <Route path="inner/7" element={<Inner7 />} />
          <Route path="inner/8" element={<Inner8 />} />
          <Route path="inner/9" element={<Inner9 />} />
          <Route path="inner/10" element={<Inner10 />} />
          <Route path="inner/11" element={<Inner11 />} />
          <Route path="inner/12" element={<Inner12 />} />
          <Route path="inner/13" element={<Inner13 />} />
          <Route path="inner/14" element={<Inner14 />} />
          <Route path="inner/15" element={<Inner15 />} />
          <Route path="inner/16" element={<Inner16 />} />
          <Route path="inner/17" element={<Inner17 />} />
          <Route path="inner/18" element={<Inner18 />} />
          <Route path="inner/19" element={<Inner19 />} />
          <Route path="refund-policy" element={<Refund />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="cookie-policy" element={<Cookie />} />
          <Route path="payment-policy" element={<PaymentGateway />} />
          <Route path="tNc-policy" element={<TnC />} />
          <Route path="shipping-policy" element={<ShippingPolicy />} />
          <Route path="/job/:id" element={<JobDetails />} />
          <Route path="/jobform/:id" element={<JobForm />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
      
    </BrowserRouter>
    </HelmetProvider>
     
    </>
  );
}

export default App;
